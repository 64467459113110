import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { PersistGate } from 'redux-persist/lib/integration/react'
import { persistStore } from 'redux-persist'
import { store } from './app/store'
import { Provider } from 'react-redux'
import { GoogleOAuthProvider } from '@react-oauth/google'

/*mercy */

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
  <Provider store={store}>
  <GoogleOAuthProvider clientId='384523218460-0liais1jvrd0q84k6nh9ncvilkmuqkrq.apps.googleusercontent.com'>
  <PersistGate loading={null} persistor={persistStore(store)}>
  <BrowserRouter>
    <Routes>
      <Route path="/*" element={<App />} />
    </Routes>
  </BrowserRouter>
  </PersistGate>
  </GoogleOAuthProvider>
  </Provider>
</React.StrictMode>
)