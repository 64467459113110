
import './App.css'
import { Routes, Route } from 'react-router-dom'
import Layout from './components/Layout'
import Home from './components/Home'
import Profile from './components/Profile'
import ModulesList from './features/module/Modules'
import Login from './components/Login'
import OpenModule from './features/module/openModule'
import Prefetch from './features/auth/Prefetch'
import About from './components/About'
import Signup from './components/Signup'
import useTitle from './hooks/Title'
import EditUser from './features/user/edituser'
import PersistLogin from './features/auth/persistLogin'
import EditUserForm from './features/user/EditUserForm'
import GoogleSignup from './components/Signup(Google)'
import Posts from './features/posts/Posts'
import Contacts from './components/Contacts'
import Splash from './components/Splash'

function App() {
  useTitle('Capcodes')
  return (
       <Routes>
        <Route path="/" element={<Splash />}/>
        <Route path='/about' element={<About/>}/>
        <Route path='/contacts' element={<Contacts/>}/>
        <Route path='/signup' element={<Signup/>}/>
        <Route path='/signupwgoogle' element={<GoogleSignup/>}/>

          <Route element={<Prefetch />}>

            <Route path="/layout" element={<Layout/>}>

                <Route index element={<Home/>}/>

                <Route path='modules'>
                  <Route index element={<ModulesList />} />
                  <Route path=":id" element={<OpenModule/>}/>
                </Route>

                <Route path='profile'>
                  <Route path=':id' element={<Profile/>}/>
                  <Route path=':id/edit' element={<EditUser/>}/>
                </Route>

                <Route path='posts'>
                  <Route index element={<Posts/>}/>
                </Route>
            </Route>
          </Route>
    </Routes>
  )
}

export default App
