import React from 'react';
import { javascript } from '@codemirror/lang-javascript';
import { atomone } from '@uiw/codemirror-theme-atomone';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import CodeMirror from '@uiw/react-codemirror';
import axios from 'axios'
import boilercode from './boilercode';
import { selectCurrentUser, updateUserCredentials} from '../auth/auth';
import { selectAllModules } from './modulesApiSlice';
import { selectAllPosts } from '../posts/postApiSlice';
import Post from '../posts/Post';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Reviews from '../posts/Reviews';
import BookmarkBorderRoundedIcon from '@mui/icons-material/BookmarkBorderRounded';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import RefreshIcon from '@mui/icons-material/Refresh';
import { Box } from '@mui/material';

const FrontendEditor = ({module}) => { 

    const { id } = useParams()

    const paperSX = {
      p: 2,
      display: 'flex',
      flexDirection: 'column',
      height: 100,
      "&:hover": {
        backgroundColor: '#B0D7FF'
      },
    }
    
    const submitSX = {
      p: 2,
      display: 'flex',
      flexDirection: 'column',
      height: 75,
      "&:hover": {
        backgroundColor: '#B0D7FF'
      },
    }

    
    //get user information from state
    const user = useSelector(selectCurrentUser)
    
    
    //dispatch variable for changing state
    const dispatch = useDispatch()
    
    //navigate variable for  possible @hint feature
    const navigate = useNavigate();
    
    //displaying output to output component
    var [output, setOutput] = useState("");
    
    //variable to hold previous rewards + new rewards
    let updated_reward
    
    const startingcode = boilercode(module.boilercode)
    
    //get all modules from state
    const modules = useSelector(selectAllModules)
    
    //get all bookmarks from state
    let bookmarks = Array.from(user.Bookmarks)

    const getLessonNumber = (module_name) => {
      var num = module_name.replace(/[^0-9]/g, ''); 
      return parseInt(num,36); 
  }
    
    const sortedmodules = [...modules].sort((a, b) => getLessonNumber(a.module_name) - getLessonNumber(b.module_name))

    const [srcDoc, setSrcDoc] =  useState('')

    const [code, setCode] =  useState(boilercode(module.boilercode))
    
    //store previous and next lessons
    let prev
    let next
    var currentModule = [...sortedmodules].find(module => module._id == id)
    
    var current = [...sortedmodules].indexOf(currentModule)
    
        prev = [...sortedmodules][current - 1]
    
        next = [...sortedmodules][current + 1]

    let [usercode, setUserCode] = useState(startingcode)


      //load the usercode or use starting code when opening assignment
    const loadUserCode = () => {
      if (localStorage.getItem(module.module_name)) {
        setUserCode(localStorage.getItem(module.module_name))
      } else {
        setUserCode(startingcode)
      }
    }

  //this will load the user code when the starting code changes
    useEffect(() => {
      loadUserCode()
    },[startingcode])


    //local state data being sent to DB
    const [submitDBData, setDBSubmitData] = useState({
      userID:user._id,
      source_code: code,
      language_id: '',
      moduleId: '',
      module_name: '',
      stdin:'Y2FwY29kZXM=',
      expected_ouput: '0',
    })


    //function to reward user
const rewarduser = async ()  => {

    //store reward from module
    var lesson_reward = module.Reward
    console.log (lesson_reward)
  
    //store all previous rewards from user. Adds new reward from module if not present
    updated_reward = Array.from(user.Rewards)
    if(!updated_reward.includes(lesson_reward)){
      updated_reward.push(lesson_reward)
    }
    console.log(updated_reward)
  
    //Create data object for state and update request. 
    var updateduserinfo = {
      _id: user._id,
      username:user.username,
      password:user.password,
      firstname:user.firstname,
      lastname:user.lastname,
      Age:user.Age,
      Streak: user.Streak,
      roles: user.roles,
      active: true,
      Rewards:updated_reward,
      next_lesson: String(module._id),
      Bookmarks:user.Bookmarks
    }
    console.log(updateduserinfo)
  
    //send updateduserinfo to DB to have user info updated
  var config = {
      method: 'PATCH',
      url: 'https://capcodes-api.onrender.com/users',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
      data: updateduserinfo
  }
  
    await axios(config)
      .then(function (response) {
          console.log(JSON.stringify(response.data))
  
          //updates state to match updated user with new reward
          dispatch(updateUserCredentials(updateduserinfo))
      })
      .catch(function (error) {
          console.log(error)
      })
  }
  
  //function to handle bookmarking
  const handleBookmarks = async ()  => {
  
    //store reward from module
    var lessonId = module._id
    console.log (lessonId)
  
    //store all previous rewards from user. Adds new reward from module if not present
    if(!bookmarks.includes(lessonId)){
      bookmarks.push(lessonId)
    }
    else {
      bookmarks = bookmarks.filter(bookmark => bookmark !== lessonId)
      console.log(lessonId)
      console.log(bookmarks)
    }
  
    //Create data object for state and update request. 
    var updateduserinfo = {
      _id: user._id,
      username:user.username,
      password:user.password,
      firstname:user.firstname,
      lastname:user.lastname,
      Age:user.Age,
      Streak: user.Streak,
      roles: user.roles,
      active: true,
      Rewards:user.Rewards,
      next_lesson: String(module._id),
      Bookmarks:bookmarks
    }
    console.log(updateduserinfo)
  
    //send updateduserinfo to DB to have user info updated
  var config = {
      method: 'PATCH',
      url: 'https://capcodes-api.onrender.com/users',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
      data: updateduserinfo
  }
  
    await axios(config)
      .then(function (response) {
          console.log(JSON.stringify(response.data))
  
          //updates state to match updated user with new reward
          dispatch(updateUserCredentials(updateduserinfo))
      })
      .catch(function (error) {
          console.log(error)
      })
  }
  
  //function to load next lesson from db
  const loadNextLesson = async ()  => {
  
    //store reward from module
    var nextLesson = String(module._id)
  
    //Create data object for state and update request. 
    var updateduserinfo2 = {
      _id: user._id,
      username:user.username,
      password:user.password,
      firstname:user.firstname,
      lastname:user.lastname,
      Age:user.Age,
      Streak: user.Streak,
      roles: user.roles,
      active: true,
      Rewards:user.Rewards,
      next_lesson: nextLesson,
      Bookmarks:user.Bookmarks
    }
  
    //send updateduserinfo to DB to have user info updated
  var config = {
      method: 'PATCH',
      url: 'https://capcodes-api.onrender.com/users',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
      data: updateduserinfo2
  }
  
    await axios(config)
      .then(function (response) {
          console.log(JSON.stringify(response.data))
  
          //updates state to match updated user with new reward
          dispatch(updateUserCredentials(updateduserinfo2))
      })
      .catch(function (error) {
          console.log(error)
      })
  }
  
  //post submission data to DB
  const posttoDB = async () => {
    console.log(submitDBData)
    var config = {
      method: 'POST',
      url: 'https://capcodes-api.onrender.com/submitPOST',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
      data: submitDBData
  }
                
    await axios.request(config)
        .then(function (response) {
            console.log(JSON.stringify(response.data))
        })
        .catch(function (error) {
            console.log(error)
        })
  }


  //custom onChange function to keep track of changes to CodeMirror. Both Judge0 and DB POST request will use this data
  const onChange = (value, viewUpdate) => {
    setCode(`<html>
    <body>${value}</body>
    </html>`)
    setDBSubmitData ({...submitDBData,language_id:module.language_id,module_name:module.module_name,moduleId:id,source_code: code})
    localStorage.setItem(module.module_name,value)
  }

  //function to send submission data to Judge0, wait for response data, then call check token function when clicking on submit
  const handleSubmit = async (e) => {
    e.preventDefault()
    setSrcDoc(code)
     output = setOutput(`<html>
                            <body><p>Code is compiling</p></body>
                        </html>`)
  rewarduser()
  posttoDB()
  loadNextLesson()

  }

  //handling refresh
  const refresh = () => {
    setUserCode(startingcode)
  }


  let prevTitle = prev ? prev.module_name : "Back To Menu"

  let nextTitle = next ? next.module_name : "End"

  let isBookmarked = Boolean(bookmarks.includes(module._id))

  //handling prev
  const handlePrev = async (e) => {
    if (prev) {
      navigate(`/layout/modules/${prev._id}`)
      setSrcDoc('')
      prevTitle = prev.module_name
    } else {
      navigate(`/layout`)
      
      prevTitle = "back to menu"
    }
  }

  //handling next
  const handleNext = async (e) => {
    if (next) {
      navigate(`/layout/modules/${next._id}`)
      setSrcDoc('')
    } else {
      navigate(`/layout`)
    }
  }


  //Component settup
  return (
    <>
  <Toolbar />
          <Container maxWidth="lg" sx={{ mb: 4 }}>
            <Grid container spacing={4}>

              {/* Lesson Name*/}
              <Grid item xs={12} md={12} lg={12} >
                <Paper
                  sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    height: 125,
                  }}
                >
                <Typography variant='h3' textAlign={'center'}>{module.module_name}</Typography>
                </Paper>
              </Grid>

              {/* Lesson Stuff*/}
              <Grid item xs={12} md={12} lg={12} zeroMinWidth>
                <Paper
                  sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    height: 300,
                  }}
                >
                <Typography variant='h5' sx={{mb: 1}}>{module.Blob_Desc}</Typography>
                <Typography variant='h5'sx={{mb: 1}}>{module.Full_Desc}</Typography>
                  <CodeMirror
                    height="75px"
                    width='725px'
                    border="1px #FFFFF"
                    overflow='auto'
                    extensions={[javascript({ jsx: true })]}
                    theme = {'dark'}
                    value = {module.Example}
                  />
                </Paper>
              </Grid>

              {/* CodeMirror */}
              <Grid item xs={12} md={12} lg={8}>
                  <CodeMirror
                      className='editor'
                      height="575px"
                      width='700px'
                      id='editor'
                      extensions={[javascript({ jsx: true })]}
                      theme = {'dark'}
                      onChange = {onChange}
                      value = {usercode}
                  />
              </Grid>

              {/* Output */}
              <Grid item xs={12} md={8} lg={4} >
                <Paper
                  sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    height: 600,
                    backgroundColor:'#B0D7FF'
                  }}
                >
                   <iframe className='frontend__output' srcDoc={srcDoc}></iframe>
                </Paper>
              </Grid>

              {/* Submit*/}
              <Grid item xs={12} md={11} lg={10} >
                <Paper
                onClick={handleSubmit}
                  sx={submitSX}
                >
                <Typography variant='h4' textAlign={'center'} mb={2} >Submit</Typography>
                </Paper>

              </Grid>

              {/* Refresh */}
              <Grid item xs={12} md={1} lg={2} >
                <Paper
                onClick={refresh}
                  sx={submitSX}
                >
                <Box m={'auto'}><RefreshIcon  sx={{ fontSize: 40}}/></Box>
                </Paper>

              </Grid>

              {/* Previous */}
              <Grid item xs={12} md={8} lg={4} >
                <Paper
                  onClick={handlePrev}
                  sx={paperSX}
                >
                  <Typography variant='h5' textAlign={'center'} mb={2}>{prevTitle}</Typography>
                </Paper>
              </Grid>

              {/* Bookmark */}
              <Grid item xs={12} md={8} lg={4} >
                <Paper
                  onClick={handleBookmarks}
                  sx={paperSX}
                >
                   <Typography variant='h5' textAlign={'center'} mb={2}> {isBookmarked ? (<Container><Typography variant='h5'>Bookmark</Typography><BookmarkIcon/></Container>) : (
               <Container><Typography variant='h5'>Bookmark</Typography><BookmarkBorderRoundedIcon/></Container>
              )}</Typography>
                </Paper>
              </Grid>

              {/* Next */}
              <Grid item xs={12} md={8} lg={4}>
                <Paper 
                onClick={handleNext}
                  sx={paperSX}
                > 
                <Typography variant='h5' textAlign={'center'} mb={2} >{nextTitle}</Typography>
                </Paper>
              </Grid>

              {/* Comments */}
              <Grid item xs={12}>
                <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', backgroundColor:'#B0D7FF'  }}>
                <Reviews moduleId={id} module_name={module.module_name}/> 
                </Paper>
              </Grid>
            </Grid>
          </Container>
  </>
    );

}

export default FrontendEditor