import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { selectUserById, useGetUsersQuery } from './usersApiSlice'
import EditUserForm from './EditUserForm'
import { selectCurrentUser } from '../auth/auth'

const EditUser = () => {

    const user = useSelector(selectCurrentUser)



    const content = user ? <EditUserForm user={user} /> : <p>Loading...</p>

    return content
}
export default EditUser