import { useSelector } from 'react-redux'
import { useAddPostMutation, useGetPostsQuery } from './postApiSlice'
import { selectCurrentUser } from '../auth/auth'
import { selectAllModules } from '../module/modulesApiSlice';
import { useEffect, useState } from 'react'
import { Box, Button, FormControl, Grid, InputLabel, MenuItem, Paper, Select, TextField, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';


//add review component
const AddReview = ({moduleId,module_name}) => {

    const getPost = useGetPostsQuery()

    const modules = useSelector(selectAllModules)

    const{ id } = useParams()

    const user = useSelector(selectCurrentUser)
    const name = `${user.firstname} ${user.lastname}`

    const[newpost, setNewPost] = useState({
        post_description: "",
        post_type: 'Review',
        post_name: `Review - ${module_name}`,
        post_user: `${user.firstname} ${user.lastname}`,
        post_module: `${id}`
    })


    useEffect(() => {
        setNewPost({...newpost,post_description:""})
    },[module_name])
    

    const [addPost] = useAddPostMutation()

    const [errMsg, setErrMsg] = useState('')

    //send review as post to db
    const handleSubmit = async (e) => {
        e.preventDefault()
        try {
           await addPost(newpost)
            console.log(newpost)
            setNewPost({
            post_description: "",
            post_type: 'Review',
            post_name: `Review - ${module_name}`,
            post_user: `${user.firstname} ${user.lastname}`,
            post_module: `${id}`
            })
        } catch (err) {
            if (!err.status) {
                setErrMsg('No Server Response');
            } else if (err.status === 400) {
                setErrMsg('Missing Username or Password');
            } else if (err.status === 401) {
            } else {
                setErrMsg(err.data?.message);
            }
        }
    }

    const paperStyle={padding :10}
    const btnstyle={margin:'12px 0', backgroundColor:'#B0D7FF', color:'#000'}

return(

        <Paper elevation={10} style = {paperStyle}> 
    <Grid align = 'center'>
        <Typography variant='h3'> Add Comment </Typography>
    </Grid>
        <TextField id="outlined-basic"  placeholder='Description[0-100]' multiline rows={4} value={newpost.post_description} onChange={(e) => setNewPost({ ...newpost, post_name:module_name, post_description: e.target.value, post_module:id})} fullWidth inputProps={{ maxLength: 100 }} required sx={{paddingTop:2}}/> 
        <Button variant="contained" style={btnstyle} onClick={handleSubmit} fullWidth> Add Post </Button>
        
    </Paper>
)
}
export default AddReview