import { apiSlice } from "../../app/api/apiSlice";
import { logOut, setCredentials, changeToken } from "./auth";


//referneces davegray MERN stack full coarse
export const authapi = apiSlice.injectEndpoints ({
    endpoints: builder => ({
        login: builder.mutation ({
            query: credentials => ({
                url: '/auth',
                method: 'POST',
                body: {...credentials}
            })
        }),
        sendLogout: builder.mutation({
            query:() => ({
                url: '/auth/logout',
                method: 'POST',
            }),
            async onQueryStarted(arg, {dispatch, queryFulfilled}) {
                try {
                    const { data } = await queryFulfilled
                    console.log(data)
                    dispatch(logOut())
                    setTimeout(() => {
                        dispatch(apiSlice.util.resetApiState())
                    }, 1000)
                } catch (err) {
                    console.log(err)
                }
            }
        }),
    refresh: builder.mutation ({
        query: () => ({
            url: '/auth/refresh',
            method: 'GET',
        }),
        async onQueryStarted(arg, { dispatch, queryFulfilled }) {
            try {
                const { data } = await queryFulfilled
                console.log(data)
                const { accessToken } = data.accessToken
                dispatch(changeToken({ token:accessToken }))
            } catch (err) {
                console.log(err)
            }
        }
    }),
    })
})

export const {
    useLoginMutation,
    useSendLogoutMutation,
    useRefreshMutation,
} = authapi