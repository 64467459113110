import { createSlice } from '@reduxjs/toolkit'

const authSlice = createSlice({
    name: 'auth',
    initialState: { token: null, user:null },
    reducers: {
        setCredentials: (state, action) => {
            const { accessToken } = action.payload
            state.token = accessToken
            state.user = action.payload.user
        },
        changeToken: (state, action) => {
            const { accessToken } = action.payload
            state.token = accessToken
        },
        updateUserCredentials: (state, action) => {
            state.user = action.payload
        },
        logOut: (state, action) => {
            state.token = null
            state.user = null
        },
    }
})

export const { setCredentials, logOut, changeToken, updateUserCredentials} = authSlice.actions

export default authSlice.reducer

export const selectCurrentToken = (state) => state.auth.token
export const selectCurrentUser = (state) => state.auth.user


