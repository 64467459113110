import {
    createSelector,
    createEntityAdapter
} from "@reduxjs/toolkit";
import { apiSlice } from "../../app/api/apiSlice"

const postsAdapter = createEntityAdapter({sortComparer: (a, b) => a.post_description.localeCompare(b.post_description)})

//references davegray MERN full stack course
const initialState = postsAdapter.getInitialState()

export const postsApiSlice = apiSlice.injectEndpoints({
    //will be needed for future post feature
    endpoints: builder => ({
        getPosts: builder.query({
            query: () => '/posts',
            validateStatus: (response, result) => {
                return response.status === 200 && !result.isError
            },
            transformResponse: responseData => {
                const loadedPosts = responseData.map(post => {
                    post.id = post._id
                    return post
                });
                return postsAdapter.setAll(initialState, loadedPosts)
            },
            providesTags: (result, error, arg) => {
                if (result?.ids) {
                    return [
                        { type: 'Post', id: 'LIST' },
                        ...result.ids.map(id => ({ type: 'Post', id }))
                    ]
                } else return [{ type: 'Post', id: 'LIST' }]
            }
        }),
        //used to signup new users
        addPost: builder.mutation({
            query: initialUserData => ({
                url:'/posts',
                method:'POST',
                body: {
                    ...initialUserData,
                }
            }),
            invalidatesTags: [{type:'Post',id:"LIST"}]
        }),
        updatePost: builder.mutation({
            query: initialUserData => ({
                url:'/posts',
                method:'PATCH',
                body: {
                    ...initialUserData,
                }
            }),
            invalidatesTags: (result,error,arg) => [{type:'Post', id: arg.id}]
        }),
        deletePost: builder.mutation({
            query: ({ id }) => ({
                url:'/posts',
                method:'Delete',
                body: { id }
            }),
            invalidatesTags: (result,error,arg) => [{type:'Post', id: arg.id}]
        }),
    }),
})

export const {
    useGetPostsQuery,
    useAddPostMutation,
    useUpdatePostMutation,
    useDeletePostMutation,
} = postsApiSlice


// returns the query result object
export const selectPostsResult = postsApiSlice.endpoints.getPosts.select()

// creates memoized selector
const selectPostData = createSelector(
    selectPostsResult,
    postsResult => postsResult.data 
)


export const {
    selectAll: selectAllPosts,
    selectById: selectPostById,
    selectIds: selectPostIds
} = postsAdapter.getSelectors(state => selectPostData(state) ?? initialState)

