import {Link, Outlet} from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useRef, useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { setCredentials } from "../features/auth/auth";
import { useLoginMutation } from "../features/auth/authapi";
import { GoogleLogin } from "@react-oauth/google";
import jwtDecode from 'jwt-decode'
//added
//import React from 'react'

import Button from '@mui/material/Button';
import  Paper  from "@mui/material/Paper";
import  Avatar from "@mui/material/Avatar";
import TextField from '@mui/material/TextField';
import  Typography  from "@mui/material/Typography";
import  Grid  from "@mui/material/Grid";
import  Box  from "@mui/material/Box";
import  Modal from "@mui/material/Modal";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: '#B0D7FF',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

const Login = () => {

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);


  //const userRef = useRef()
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const [errMsg, setErrMsg] = useState('')

   


   const navigate = useNavigate();
   const dispatch = useDispatch()

   const [login, { isLoading } ] = useLoginMutation()



    const handleSubmit = async (e) => {
      e.preventDefault()
      try {
          const { accessToken,user } = await login({ username, password }).unwrap()
          dispatch(setCredentials( 
          {
            accessToken,
            user,
          }))
          console.log(user)
          console.log(accessToken)
          setErrMsg('')
          navigate('/layout')
      } catch (err) {
          if (!err.status) {
              setErrMsg('No Server Response');
              setOpen(true)
          } else if (err.status === 400) {
              setErrMsg('Missing Username or Password');
              setOpen(true)
          } else if (err.status === 401) {
              setErrMsg('Wrong Credential!  Please Try Again');
              setUsername('')
              setPassword('')
              setOpen(true)
          } else {
              setErrMsg(err.data?.message);
          }
      }
  }

  //function to clear all the usercode in local storage
  const clearUserCode = () => {
    var key
    for (var i = 0; i < localStorage.length; i++) {
       key = localStorage.key(i)
      if(key != 'persist:root') {
        localStorage.removeItem(key)
      }
    }
  }

  //makes sure storage is clear when opening web app
  useEffect(() => {
    clearUserCode()
  },[])

  const handleUserInput = (e) => setUsername(e.target.value)
  const handlePwdInput = (e) => setPassword(e.target.value)

  //add
    const paperStyle={padding :30,height:425,width:400, margin:'2.5rem auto',}
    const avatarStyle={backgroundColor:'#1c6cbc'}
    const btnstyle={margin:'5px 0'}

//didn't add type='password' in password textfield
   return (
        <Grid>
            <Paper elevation={10} style = {paperStyle}> 
                <Grid align = 'center'>
                <Avatar style={avatarStyle}> CC </Avatar>
                    <h2>Capcodes</h2>
                </Grid>
                <TextField id="outlined-basic" label='username' placeholder='username' data-test-id="test-username" value={username} onChange={(e) => setUsername(e.target.value)} fullWidth required/>
                <TextField  id="outlined-basic" label='password' placeholder='password' data-test-id="test-password" value={password} onChange={(e) => setPassword(e.target.value)} fullWidth required/> 

               
               <Button variant="contained" style={btnstyle} onClick={handleSubmit} fullWidth> Sign In </Button>
               <Modal
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                >
                        <Box sx={style}>
                        <Typography id="modal-modal-title" data-testid="modal" variant="h6" component="h2">
                            Error!
                        </Typography>
                        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                            {errMsg}
                        </Typography>
                        </Box>
                </Modal>
               <Typography > Do you have an account? 
                     <Link to="/signup" > Sign Up! </Link>
                     
                </Typography>

                    <GoogleLogin onSuccess={async (credentialResponse) =>  {
                        console.log(credentialResponse)
                        const accessToken = null
                        const user =  await jwtDecode(credentialResponse.credential)
                        const username = user.email
                        const password = 'oLDlNyU3UZO1ofNi6Ap8'
                        try {
                            const { accessToken,user } = await login({ username, password }).unwrap()
                            dispatch(setCredentials( 
                            {
                              accessToken,
                              user,
                            }))
                            console.log(user)
                            console.log(accessToken)
                            navigate('/layout')
                        } catch (err) {
                            if (!err.status) {
                                setErrMsg('No Server Response');
                            } else if (err.status === 400) {
                                setErrMsg('Missing Username or Password');
                            } else if (err.status === 401) {
                                dispatch(setCredentials({
                                    accessToken,
                                    user
                                }))
                                navigate('/signupwgoogle')
                            } else {
                                setErrMsg(err.data?.message);
                            }
                        }
                    } }
                    text="signin_with"
                    className= "google_login"
                    onError = { () => {
                        console.log('failed to login')
                    }}>Log In with Google</GoogleLogin>
            </Paper>

        </Grid>
   )
        
    
}
  
  export default Login;


