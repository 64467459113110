import { useNavigate } from 'react-router-dom'

import { useSelector } from 'react-redux'
import { useGetPostsQuery } from './postApiSlice'
import { selectCurrentUser } from '../auth/auth'
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';

//styles
const postSX = {
  "&:hover": {
    backgroundColor: '#B0D7FF'
  },
}

const Post = ({ postId }) => {

  //rtk query posts
    const { post } = useGetPostsQuery("postList", {
        selectFromResult: ({ data }) => ({
            post: data?.entities[postId]
        }),
    })

    const navigate = useNavigate()
    const user = useSelector(selectCurrentUser)


    //function to get initials of user
    const getInitials = (fullName) => {
      const allNames = fullName.trim().split(' ');
      const initials = allNames.reduce((acc, curr, index) => {
        if(index === 0 || index === allNames.length - 1){
          acc = `${acc}${curr.charAt(0).toUpperCase()}`;
        }
        return acc;
      }, '');
      return initials;
    }


    if (post) {
        
      //go from post to module
        const handlestart = () => navigate(`/layout/modules/${post.post_module}`)


        return (    
        <>
        <ListItem alignItems="flex-start" onClick={handlestart} sx={postSX}>
        <ListItemAvatar>
          <Avatar>{getInitials(post.post_user)}</Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={post.post_description}
          secondary={
            <>
              <Typography
                sx={{ display: 'inline' }}
                component="span"
                variant="body2"
                color="text.primary"
              >
                {post.post_user}
              </Typography>
              : {post.post_name}
            </>
          }
        />
      </ListItem>
      <Divider variant="inset" component="li" />
      </>
        )



    } else return null
}
export default Post