import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { selectModulesById } from './modulesApiSlice'
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { Avatar, Tooltip } from '@mui/material';
import { selectCurrentUser } from '../auth/auth';

const Module = ({ moduleId }) => {

  //get modules from store
    const module = useSelector(state => selectModulesById(state, moduleId))

  //get user from store
    const user = useSelector(selectCurrentUser)

  //navigate to page
    const navigate = useNavigate()

  //variable to hold name
    let lname = ''

    //changes color based on reward received
    function stringToColor() {
        let color = ''
        const rewards = Array.from(user.Rewards)
        if (rewards.includes(module.Reward)){
            color = `#00FF00`
        }
        else {
            color = `#808080`
        }
      
        return color;
      }

      // function to induce style
      function stringAvatar() {
        return {
          sx: {
            bgcolor: stringToColor(),
          }
        };
      }

      //Function to change tooltipa
      function TooltipText() {
        let text = ''
        const rewards = Array.from(user.Rewards)
        if (rewards.includes(module.Reward)){
            text = `Module completed`
        }
        else {
            text = `Module Incomplete`
        }
        return text;
      }
    if (module) {
        if (module.language_id == 62) {
            lname = 'JA'
        } else if (module.language_id == 63) {
            lname = 'JS'
        } else if (module.language_id == 53) {
            lname = 'C++'
        } else if (module.language_id == 85) {
            lname = '</>'
        } else if (module.language_id == 86) {
            lname = 'CS'
        }

        //navigate to module
        const handlestart = () => navigate(`/layout/modules/${moduleId}`)

        return (
            <ListItem disablePadding>
            <Tooltip title={TooltipText()}>
            <ListItemButton onClick={handlestart}>
              <ListItemIcon>
                <Avatar {...stringAvatar()}>{lname}</Avatar>
              </ListItemIcon>
              <ListItemText primary={module.module_name} />
            </ListItemButton>
            </Tooltip>
          </ListItem>

            
        )

    } else return null
}
export default Module