import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { selectModulesById } from './modulesApiSlice'
import FrontendEditor from './FrontendEditor'
import BackendEditor from './BackendEditor'

//This is what is rendered to allow us to connect open assignment with a module
const OpenModule = () => {
    const { id } = useParams()

    const module = useSelector(state => selectModulesById(state, id))

    var content

    if (module) {
        if (module.language === "HTML" || module.language === "CSS") {
            content = <FrontendEditor module={module}/>
        } else {
            content = <BackendEditor module={module}/>
        }
    } else
    content = <p className='module-loading'>Loading...</p>


    //const content = module ? handleEditor(module) : <p className='module-loading'>Loading...</p>

    return content
}
export default OpenModule