import { useNavigate } from 'react-router-dom'

import { useSelector } from 'react-redux'
import { selectPostById, useAddPostMutation } from './postApiSlice'
import Dropdown from 'react-dropdown';
import { selectModulesById } from '../module/modulesApiSlice'
import { selectCurrentUser } from '../auth/auth'
import { selectAllModules } from '../module/modulesApiSlice';
import { useState } from 'react'
import { Box, Button, FormControl, Grid, InputLabel, MenuItem, Paper, Select, TextField, Typography } from '@mui/material';


//code to add a post
const AddPost = () => {

    let lessonId

     const navigate = useNavigate()

    const modules = useSelector(selectAllModules)

    const modIds = modules.map(mod => mod._id)
    console.log(modIds)


    const user = useSelector(selectCurrentUser)
    const name = `${user.firstname} ${user.lastname}`

    const[newpost, setNewPost] = useState({
        post_description: "",
        post_type:'Post',
        post_name: "",
        post_user: name,
        post_module: ""
    })

    const [addPost] = useAddPostMutation()

    const [errMsg, setErrMsg] = useState('')

    //send post to db
    const handleSubmit = async (e) => {
        e.preventDefault()
        
        try {
            await addPost(newpost)
            console.log(newpost)
            setNewPost({...newpost, post_description:"", post_name:"" })
            
        } catch (err) {
            if (!err.status) {
                setErrMsg('No Server Response');
            } else if (err.status === 400) {
                setErrMsg('Missing Username or Password');
            } else if (err.status === 401) {
            } else {
                setErrMsg(err.data?.message);
            }
        }
    }

    const options = ['Lesson 1 : Working with Strings in JAVA',
    'Lesson 2 : Working with Integers in JAVA',
   'Lesson 3 : Working with array in JAVA',
   'Lesson 4 : Working with Strings in Javascript',
   'Lesson 5 : Working with Integers in Javascript',
    'Lesson 6 : Working with Outputs in C++',
   'Lesson 7 : Working with Incrementing in C++',
    'Lesson 8 : Working with Header 1 in HTML',
   'Lesson 9 : Working with Bold Tags in HTML',
    'Lesson 10: Styling with Color in CSS']

    const [lesson, setLesson] = useState('');

    //handle select menu 
  const handleChange = (event) => {
    let val = event.target.value
    lessonId =  modules.find(module => module.module_name === val)
    console.log(lessonId._id)
    setNewPost({...newpost, post_module:lessonId._id})
    setLesson(event.target.value)
    
  }
    

  //styles
    const paperStyle={padding :10}
    const btnstyle={margin:'12px 0', backgroundColor:'#B0D7FF', color:'#000'}
    

return(

            <Paper elevation={10} style = {paperStyle}> 
            <Grid align = 'center'>
                <Typography variant='h3'> Add Post </Typography>
            </Grid>
                <TextField id="outlined-basic"  placeholder='Title[0-15]' value={newpost.post_name} onChange={(e) => setNewPost({ ...newpost, post_name: e.target.value})} fullWidth inputProps={{ maxLength: 15 }} required sx={{paddingTop:2}}/>
                <TextField id="outlined-basic"  placeholder='Description[0-100]' multiline rows={4} value={newpost.post_description} onChange={(e) => setNewPost({ ...newpost, post_description: e.target.value})} fullWidth inputProps={{ maxLength: 100 }} required sx={{paddingTop:2}}/> 
                <Box paddingTop={2}>
                <FormControl fullWidth required >
                    <InputLabel id="demo-simple-select-label">Choose Lesson</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={lesson}
                            placeholder='Choose Lesson'
                            onChange={handleChange}
                        >
                            {options.map(option => <MenuItem value={option}>{option}</MenuItem>)}
                        </Select>
                </FormControl>
                </Box>
               <Button variant="contained" style={btnstyle} onClick={handleSubmit} fullWidth> Add Post </Button>
               
            </Paper>
)
}
export default AddPost