import Typography from '@mui/material/Typography';
import React from "react";

// output code
const CodeOutput = ({ output }) => {
	return (
		<div>
			<Typography>{output}</Typography>
		</div>
	);
};

export default CodeOutput;