
import BarChart from "../features/submits/submitBarChart";
import SubmitsList from "../features/submits/submitTotal";
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Previous_Module from "../features/module/PreviousModule_dashboard";
import ProgressBar from "../features/module/progressbar";


//home page
const Home = () => {

    return (
    <>
    <div  className="homescreen" data-testid="dashboard">
          <Toolbar />
          <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <Grid container spacing={4}>

               {/* Previous*/}
               <Grid data-testid='previous' item xs={12} md={8} lg={12} >
                <Paper
                  sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    height: 300,
                  }}
                >
                  <Previous_Module/>
                </Paper>
              </Grid>

              {/* Donut Chart */}
              <Grid item xs={12} md={8} lg={5}>
                <Paper
                  sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    height: 300,
                  }}
                >
                  <iframe className ="donut" data-testid="donut" src="https://charts.mongodb.com/charts-capcodes-database-igkqn/embed/charts?id=6384207c-b644-4776-8f8a-fbc12406d01b&maxDataAge=86400&theme=light&autoRefresh=true"></iframe>
                </Paper>
              </Grid>

              {/* User Submits */}
              <Grid item xs={12} md={8} lg={3} >
                <Paper
                  sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    height: 300,
                    backgroundColor:'#B0D7FF'
                  }}
                >
                  <SubmitsList/>
                </Paper>
              </Grid>

              {/* Users Chart*/}
              <Grid item xs={12} md={8} lg={4} >
                <Paper
                  sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    height: 300,
                  }}
                >
                  <iframe className="users_chart"  src="https://charts.mongodb.com/charts-capcodes-database-igkqn/embed/charts?id=6384207c-b644-42ef-8155-fbc12406d01d&maxDataAge=3600&theme=light&autoRefresh=true"></iframe>
                </Paper>
              </Grid>

              {/* Total Chart*/}
              <Grid item xs={12} md={8} lg={7} >
                <Paper
                  sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    height: 300,
                  }}
                >
                  <iframe className="users_chart"  src="https://charts.mongodb.com/charts-capcodes-database-igkqn/embed/charts?id=6384207c-b644-41bd-8855-fbc12406d00d&maxDataAge=300&theme=light&autoRefresh=true"></iframe>
                </Paper>
              </Grid>

              {/* User Progress*/}
              <Grid item xs={12} md={8} lg={5} >
                <Paper
                  sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    height: 300,
                  }}
                > 
                <ProgressBar/>
                </Paper>
              </Grid>

              {/* Recent Orders */}
              <Grid item xs={12}>
                <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', backgroundColor:'#B0D7FF' }}>
                <BarChart/>
                </Paper>
              </Grid>
            </Grid>
          </Container>
          </div>
    </>
    )}
  
  export default Home