import { useGetAllSubmitsQuery } from './submitApiSlice.js'
import { selectAllModules } from '../module/modulesApiSlice'
import { useSelector } from 'react-redux'
import { Bar } from 'react-chartjs-2';
import ChartRace from 'react-chart-race';
import { selectCurrentUser } from '../auth/auth.js';

//render race chart
const BarChart = () => {
  //rtk query submits
  const {
      data: submits,
      isLoading,
  } = useGetAllSubmitsQuery("submitsList", {
    refetchOnMountOrArgChange:true
})

const user = useSelector(selectCurrentUser)

  if (isLoading) return <p className='module-loading'>Loading...</p>

//code to reformat data based on number of occurences in submits
  const  getNbOccur = (module_name, arr) => {
    var occurs = 0;
    for (var i=0; i<arr?.length; i++) {
      if ( 'module_name' in arr[i] && arr[i].module_name === module_name && arr[i].userID === user._id ) occurs++;
    }
    return occurs;
  }


    return (
        <div>
         <ChartRace data={[
  { id: 0, title: 'Lesson 1: Java', value: getNbOccur('Lesson 1 : Working with Strings in JAVA',submits), color: '#50c4fe' },
  { id: 1, title: 'Lesson 2: Java', value: getNbOccur('Lesson 2 : Working with Integers in JAVA',submits), color: '#50c4fe' },
  { id: 2, title: 'Lesson 3: Java', value: getNbOccur('Lesson 3 : Working with array in JAVA',submits), color: '#50c4fe' },
  { id: 3, title: 'Lesson 4: Javascript', value: getNbOccur('Lesson 4 : Working with Strings in Javascript',submits), color: '#c8303b' },
  { id: 4, title: 'Lesson 5: Javascript', value: getNbOccur('Lesson 5 : Working with Integers in Javascript',submits), color: '#c8303b' },
  { id: 5, title: 'Lesson 6: C++', value: getNbOccur('Lesson 6 : Working with Outputs in C++',submits), color: '#2c2c2c' },
  { id: 6, title: 'Lesson 7: C++', value: getNbOccur('Lesson 7 : Working with Incrementing in C++',submits), color: '#2c2c2c' },
  { id: 7, title: 'Lesson 8: HTMl', value: getNbOccur('Lesson 8 : Working with Headers in HTML',submits), color: '#423bce' },
  { id: 8, title: 'Lesson 9: HTML', value: getNbOccur('Lesson 9 : Working with Bold Tags in HTML',submits), color: '#423bce' },
  { id: 9, title: 'Lesson 10: CSS ', value: getNbOccur('Lesson 10: Styling with Color in CSS',submits), color: '#c33178' } 
]} 
width={1120}
padding={12}
itemHeight={25}
gap={12}
titleStyle={{ font: 'normal 300 13px Arial', color: 'black' }}
backgroundColor='##B0D7FF'
/>
        </div>
    )

}
export default BarChart