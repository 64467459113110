import {selectAllModules, useGetModulesQuery} from '../module/modulesApiSlice.js'
import Module from './module_sidebar.js';
import { useSelector } from 'react-redux'

import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import InboxIcon from '@mui/icons-material/Inbox';
import DraftsIcon from '@mui/icons-material/Drafts';

const ModulesList = () => {
  const {
      data: modules,
      isLoading,
      isSuccess,
      isError,
      error
  } = useGetModulesQuery("moduleList", {
    refetchOnMountOrArgChange:true
})



const allmodules = useSelector(selectAllModules);

  let content

  if (isLoading) content = <p className='module-loading'>Loading...</p>

  if (isError) {
      content = <p className="errmsg">{error?.data?.message}</p>
  }

  if (isSuccess) {
    const { ids, entities } = modules

    //Filter and Sort Java Ids based on module name
    let JavaIds

    const Javacontent = [...allmodules].filter(allmodules => allmodules.language === "Java").sort((a, b) => a.module_name.localeCompare(b.module_name))

    JavaIds = Javacontent.map(id => id._id)

    //Javascript
    let JavascriptIds
        
    const Javascriptcontent = [...allmodules].filter(allmodules => allmodules.language === "Javascript").sort((a, b) => a.module_name.localeCompare(b.module_name))

    JavascriptIds = Javascriptcontent.map(id => id._id)


    //C++ 
    let CppIds

    const Cppcontent = [...allmodules].filter(allmodules => allmodules.language === "C++").sort((a, b) => a.module_name.localeCompare(b.module_name))

    CppIds = Cppcontent.map(id => id._id)

    //HTML
    let HTMLIds

    const HTMLcontent = [...allmodules].filter(allmodules => allmodules.language === "HTML").sort((a, b) => a.module_name.localeCompare(b.module_name))

    HTMLIds = HTMLcontent.map(id => id._id)

    //CSS

    let CSSIds

    const CSScontent = [...allmodules].filter(allmodules => allmodules.language === "CSS").sort((a, b) => a.module_name.localeCompare(b.module_name))

    CSSIds = CSScontent.map(id => id._id)


    //Mapping Ids to Lessons
    const JavafilteredContent = JavaIds.length ? JavaIds.map(moduleId => <Module key={moduleId} moduleId={moduleId} />) : <tr><td className='No__Mods'>Check back soon for more content</td></tr>

    const JavascriptfilteredContent = JavascriptIds.length ? JavascriptIds.map(moduleId => <Module key={moduleId} moduleId={moduleId} />) : <tr><td className='No__Mods'>Check back soon for more content</td></tr>

    const CppfilteredContent = CppIds.length ? CppIds.map(moduleId => <Module key={moduleId} moduleId={moduleId} />) : <tr><td className='No__Mods'>Check back soon for more content</td></tr>

    const HTMLfilteredContent = HTMLIds.length ? HTMLIds.map(moduleId => <Module key={moduleId} moduleId={moduleId} />) : <tr><td className='No__Mods'>Check back soon for more content</td></tr>

    const CSSfilteredContent = CSSIds.length ? CSSIds.map(moduleId => <Module key={moduleId} moduleId={moduleId} />) : <tr><td className='No__Mods'>Check back soon for more content</td></tr>



      content = (

       <List>
        {JavafilteredContent}
        <Divider/>
        {JavascriptfilteredContent}
        <Divider/>
        {CppfilteredContent}
        <Divider/>
        {HTMLfilteredContent}
        <Divider/>
        {CSSfilteredContent}
       </List>

        

        
      )
  }

  return content
}
export default ModulesList