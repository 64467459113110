import { useGetAllSubmitsQuery } from './submitApiSlice.js';
import { selectAllModules } from '../module/modulesApiSlice';
import { useSelector } from 'react-redux'


import Typography from '@mui/material/Typography';
import { Box } from '@mui/material';
import { selectCurrentUser } from '../auth/auth.js';

//render totals
const SubmitsList = () => {
  const {
      data: submits,
      isLoading,
      isSuccess,
      isError,
      error
  } = useGetAllSubmitsQuery("submitsList", {
    pollingInterval:3000,
    refetchOnMountOrArgChange:true
})

const user = useSelector(selectCurrentUser)


  let content

  if (isLoading) content = <p className='module-loading'>Loading...</p>

  if (isError) {
      content = <p className="errmsg">{error?.data?.message}</p>
  }

  if (isSuccess) {
   let total = submits.filter(submit => submit.userID === user._id).length
   console.log(total)
   
    




      content = (
        <>
        <Box mt={5}>
        <Typography variant='h6' sx={{textAlign:'center'}}>Submits</Typography>
        <Typography variant='h1' sx={{textAlign:'center'}}>{total}</Typography>
        </Box>
        </>

        

        
      )
  }

  return content
}
export default SubmitsList