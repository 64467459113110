import {Link, Outlet} from "react-router-dom"
import { useNavigate } from "react-router-dom"
import { useState, useEffect } from 'react'
import axios from "axios";
import { selectCurrentUser } from "../features/auth/auth"
import { useSelector } from "react-redux"
import { useDispatch } from 'react-redux'
import { useLoginMutation } from "../features/auth/authapi";
import { setCredentials } from "../features/auth/auth";
//added
import Button from '@mui/material/Button';
import  Paper  from "@mui/material/Paper";
import  Avatar from "@mui/material/Avatar";
import TextField from '@mui/material/TextField';
import  Typography  from "@mui/material/Typography";
import  Grid  from "@mui/material/Grid";



const GoogleSignup = () => {

    const navigate = useNavigate()

    const user = useSelector(selectCurrentUser)

    const dispatch = useDispatch()

    const [login, { isLoading } ] = useLoginMutation()

    const [errMsg, setErrMsg] = useState('')


    //added
    const paperStyle={padding :30,height:500,width:300, margin:'5rem auto',}
    const avatarStyle={backgroundColor:'#1c6cbc'}
    const btnstyle={margin:'17px 0'}

    const [userData, setUserData] = useState ({
        username:user.email,
        password:'oLDlNyU3UZO1ofNi6Ap8',
        firstname:user.given_name,
        lastname:user.family_name,
        Age:'',
        Streak: 0,
        roles: ["user"],
        active: true,
        Rewards:[],
        next_lesson:'6383d06cf8387112e82da5e8',
        Bookmarks:[]
    })


    const onUsernameChanged = e => setUserData({...userData, username: e.target.value})
    const onPasswordChanged = e => setUserData({...userData, password: e.target.value})
    const onFirstnameChanged = e => setUserData({...userData, firstname: e.target.value})
    const onLastNameChanged = e => setUserData({...userData, lastname: e.target.value})
    const onAgeChanged = e => setUserData({...userData, Age: e.target.value})

    const handleSubmit = async (e) => {
        e.preventDefault()
        let next 
        var config = {
            method: 'POST',
            url: 'https://capcodes-api.onrender.com/users',
            headers: {
              'Content-Type': 'application/json',
              'Access-Control-Allow-Origin': '*',
            },
            data: userData
        };
                    
       await axios(config)
            .then(function (response) {
                console.log(JSON.stringify(response.data))
                alert('Account created!')
            })
            .catch(function (error) {
                console.log(error)
            })


            try {
                const username = userData.username
                const password = userData.password
                const { accessToken,user } = await login({ username, password }).unwrap()
                dispatch(setCredentials( 
                {
                  accessToken,
                  user,
                }))
                console.log(user)
                console.log(accessToken)
                navigate('/layout')
            } catch (err) {
                if (!err.status) {
                    setErrMsg('No Server Response');
                } else if (err.status === 400) {
                    setErrMsg('Missing Username or Password');
                } else if (err.status === 401) {
                    setErrMsg('Unauthorized');
                } else {
                    setErrMsg(err.data?.message);
                }
            }
    }


   
   return (
    <Grid>
        <Paper elevation={10} style = {paperStyle}> 
            <Grid align = 'center'>
            <Avatar style={avatarStyle}> CC </Avatar>
                <h2> Edit Account from Google </h2>
            </Grid>
                <TextField id="outlined-basic" label='username' placeholder='username[0-15]' value={userData.username} onChange={(e) => setUserData({...userData, username: e.target.value})} fullWidth inputProps={{ maxLength: 12 }} required/>
                <TextField id="outlined-basic" label='first name' placeholder='first name[0-15]' value={userData.firstname} onChange={(e) => setUserData({...userData, firstname: e.target.value})} fullWidth inputProps={{ maxLength: 12 }}required/>
                <TextField id="outlined-basic" label='last name' placeholder='last name' value={userData.lastname} onChange={(e) => setUserData({...userData, lastname: e.target.value})} fullWidth inputProps={{ maxLength: 12 }}required/> 
                <TextField id="outlined-basic" label='age' placeholder='age' value={userData.Age} onChange={(e) => setUserData({...userData, Age: e.target.value})} fullWidth inputProps={{ maxLength: 12 }}required/> 

               <Button variant="contained" style={btnstyle} onClick={handleSubmit} fullWidth> Create Account </Button>
               {/* <Button type='submit' color='blue' variant="contained" style={btnstyle} fullWidth> Sign Up </Button> */}
               
            </Paper>

        </Grid>
     )
    };


/* <>
    <div className= "text-box">
    <h1>Signup</h1>
        <p> This is from google. Feel free to edit or change any information to your liking!</p>
        <form className="signup-form" onSubmit={handleSubmit}>
                    <label className="username">Username:</label>
                    <input
                        className="form__input"
                        type="text"
                        id="username"
                        onChange={onUsernameChanged}
                        autoComplete="off"
                        value = {userData.username}
                    ></input>

                    <label className="firstname">Firstname:</label>
                    <input
                        className="form__input"
                        type="text"
                        id="username"
                        onChange={onFirstnameChanged}
                        autoComplete="off"
                        value={userData.firstname}
                    ></input>

                    <label className="lastname">Lastname:</label>
                    <input
                        className="form__input"
                        type="text"
                        id="lastname"
                        onChange={onLastNameChanged}
                        autoComplete="off"
                        value={userData.lastname}
                    ></input>


                    <label className="age">Age:</label>
                    <input
                        className="form__input"
                        type="text"
                        id="age"
                        onChange={onAgeChanged}
                        autoComplete="off"
                        
                    />


                    <button className="form__submit-button"
                    >Create Account</button>
                </form>
</div>

<Outlet/>

</> */

  
  
  export default GoogleSignup;