const setupsourcecode = (boilercode,testcase,usercode) => {
//sets compile code with usecode and testcase for submissions
    var sourcecode
        if (boilercode === 'JAVA:Lesson1' || boilercode === 'JAVA:Lesson2' || boilercode === 'JAVA:Lesson3') {
            sourcecode = `public class Main {
                public static void main(String[] args) {
                  ${usercode}
                  ${testcase}
                }
              }`
        }
        else if(boilercode === 'JAVASCRIPT:Lesson1' || boilercode === 'JAVASCRIPT:Lesson5') {
          sourcecode = `${usercode}
          ${testcase}`
        }
        else if(boilercode === 'Cpp:Lesson7' || boilercode === 'Cpp:Lesson9') {
          sourcecode = 
          `#include <iostream>
          using namespace std;
          int main() {
            ${usercode}
            ${testcase}
            return 0;
          }
          `
        }
        return sourcecode
}
export default setupsourcecode