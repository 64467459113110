import { useState, useEffect } from "react"
import { useUpdateUserMutation, useDeleteUserMutation } from "./usersApiSlice"
import { useNavigate } from "react-router-dom"
import { selectCurrentUser, updateUserCredentials } from "../auth/auth"
import axios from "axios"
import { useDispatch } from "react-redux"
import Button from '@mui/material/Button'
import  Paper  from "@mui/material/Paper"
import  Avatar from "@mui/material/Avatar"
import TextField from '@mui/material/TextField'
import  Typography  from "@mui/material/Typography"
import  Grid  from "@mui/material/Grid"


const EditUserForm = ({ user }) => {

    const dispatch = useDispatch()

    const navigate = useNavigate()

    const [userData, setUserData] = useState ({
        _id: user._id,
        username: user.username,
        password: user.password,
        firstname: user.firstname,
        lastname: user.lastname,
        Age: user.Age,
        Streak: user.Streak,
        roles: user.roles,
        active: true,
        Rewards:user.Rewards,
        next_lesson: user.next_lesson,
        Bookmarks:user.Bookmarks
    })


    const onSaveUserClicked = async (e) => {


            
            
              //send updateduserinfo to DB to have user info updated
            var config = {
                method: 'PATCH',
                url: 'https://capcodes-api.onrender.com/users',
                headers: {
                  'Content-Type': 'application/json',
                  'Access-Control-Allow-Origin': '*',
                },
                data: userData
            }
            
              await axios(config)
                .then(function (response) {
                    console.log(JSON.stringify(response.data))
            
                    //updates state to match updated user with new reward
                    dispatch(updateUserCredentials(userData))
                    navigate(`/layout/profile/${user._id}`)
                })
                .catch(function (error) {
                    console.log(error)
                })
            }

            const paperStyle={padding :30,height:700,width:300, margin:'5rem auto',}
            const avatarStyle={backgroundColor:'#1c6cbc'}
            const btnstyle={margin:'17px 0'}


    if (user) {
        const handleEdit = async (e) => {
            await onSaveUserClicked()
            navigate(`/layout/profile/${user.userID}`)
    }

    const content = (
        <>

<Grid>
        <Paper elevation={10} style = {paperStyle}> 
            <Grid align = 'center'>
            <Avatar style={avatarStyle}> CC </Avatar>
                <Typography paddingBottom={1}> Edit Account </Typography>
                <Typography paddingBottom={1}> Note: Changing password with Google Sign In will disable it. You will need to sign in with your email and new password if changed</Typography>
            </Grid>
                <TextField id="outlined-basic"  placeholder='username[0-15]' data-testid="username" value={userData.username} onChange={(e) => setUserData({...userData, username: e.target.value})} fullWidth inputProps={{ maxLength: 15 }}required sx={{paddingTop:1}}/>
                <TextField id="outlined-basic"  placeholder='password[0-15]'  onChange={(e) => setUserData({...userData, password: e.target.value})} fullWidth required sx={{paddingTop:1}}/> 
                <TextField id="outlined-basic"  placeholder='first name' value={userData.firstname} onChange={(e) => setUserData({...userData, firstname: e.target.value})} fullWidth inputProps={{ maxLength: 15 }}required sx={{paddingTop:1}}/>
                <TextField id="outlined-basic"  placeholder='last name' value={userData.lastname} onChange={(e) => setUserData({...userData, lastname: e.target.value})} fullWidth inputProps={{ maxLength: 50 }}required sx={{paddingTop:1}}/> 
                <TextField id="outlined-basic"  placeholder='age' type="number" value={userData.Age} onChange={(e) => setUserData({...userData, Age: e.target.value})} fullWidth inputProps={{ maxLength: 50 }}required sx={{paddingTop:1}}/> 

               <Button variant="contained" style={btnstyle} onClick={handleEdit} fullWidth> Save User </Button>
               {/* <Button type='submit' color='blue' variant="contained" style={btnstyle} fullWidth> Sign Up </Button> */}
               
            </Paper>

        </Grid>


        </>
    )

    return content

}
}
export default EditUserForm