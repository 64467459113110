const boilercode = (boilercode) => {
    var code

//set boilercode for each module based on received data
if (boilercode === 'JAVA:Lesson1') {
            code = `//modify the <String>
        String NAME = <String>;`
}

if(boilercode === 'JAVA:Lesson2') {
            code = `//add the two integer variables
        int a = 7;
        int b = 3;
        //modify the <ADD>
        int sum = <ADD>;`
}

if(boilercode === 'JAVA:Lesson3') {
            code = 
`//modify the <ArrayofStrings>, <ArrayofIntegers>, and <ArrayofCharactrers>

String[] myStringArray = <ArrayofStrings>;

int[] myIntArray = <ArrayofIntegers>;

char[] myCharArray = <ArrayofCharactrers>;
            `
}

if(boilercode === 'JAVASCRIPT:Lesson1') {
            code = `
        //modify the <String>          
        let mystring = <String>;`
}

if(boilercode === 'JAVASCRIPT:Lesson5') {
            code = 
        `//modify the <Integer>          
        let int = <Integer>;
        `
}

if(boilercode === 'HTML:Lesson6') {
            code = 
`/* With h1*/
<h1>Capcodes</h1>

 /* Without h1*/
Capcodes`}


if(boilercode === 'HTML:Lesson8') {
    code = 
`
<p>Capcodes is <b>AWESOME</b></p>
`}

if(boilercode === 'Cpp:Lesson7') {
    code = 
`//change the x variable
int x = 7;
cin >> x; // Get user input from the keyboard
cout << "Your number is:  " << x << endl;
    `
}

if(boilercode === 'Cpp:Lesson9') {
    code = 
`//modify temp variable to increment post by 1
int post = 5;

int temp = //<change here>;
    `
}

if(boilercode === 'CSS:Lesson10') {
    code = 
`
<h1 style="color:#B0D7FF;">Capcodes primary color</h1>
    `
}



        return code
}
export default boilercode