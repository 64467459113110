import { useSelector } from 'react-redux'
import AddPost from './AddPost.js'
import AddReview from './AddReview.js'
import Post from './Post.js'
import { selectAllPosts, useGetPostsQuery } from './postApiSlice.js'
import { useEffect, useState } from 'react'
import { Box, Container, Divider, Grid, List, Pagination, Paper, Typography, makeStyles } from '@mui/material'

const Reviews = ({moduleId,module_name}) => {
  //rtk query posts
  const {
      data: posts,
      isLoading,
      isSuccess,
      isError,
      error
  } = useGetPostsQuery("postList", {
    refetchOnFocus:true,
    refetchOnMountOrArgChange:true
})

  let content


//pagination stuff
  const itemsPerPage = 7
  const [page, setPage] = useState(1)
  const handleChange = (event, value) => {
  setPage(value);
};

  if (isLoading) content = <p className='module-loading'>Loading...</p>

  if (isError) {
      content = <p className="errmsg">{error?.data?.message}</p>
  }

  if (isSuccess) {
    
    //get memoized ids and entities
    const { ids, entities } = posts

    let reviewIds

    //filter post by the moduleid
    reviewIds = ids.filter(postId => entities[postId].post_type === 'Review' && entities[postId].post_module == (moduleId))

    //dynamically generate number of pages
    let noOfPages = Math.ceil(reviewIds.length / itemsPerPage)



    content = (
      <Container maxWidth="lg" sx={{ mt: 4, mb: 4}}>
          <Grid container spacing={4} >

             {/* Previous*/}
             <Grid item xs={12} md={8} lg={12} >
              <Paper
                sx={{
                  p: 2,
                  display: 'flex',
                  flexDirection: 'column',
                  height: 75,
                }}
              >
                <Typography variant='h4' textAlign={'center'}>Comments</Typography>
              </Paper>
            </Grid>

            {/* Donut Chart */}
            <Grid item xs={12} md={12} lg={12}>
              <Paper
                sx={{
                  p: 2,
                  display: 'flex',
                  flexDirection: 'column',
                  height: 'fit-content',
                }}
              >
                <Divider/>
                  <List dense component="span">
                    {reviewIds.length ? reviewIds
                      .slice((page - 1) * itemsPerPage, page * itemsPerPage)
                      .map(reviewId => <Post key={reviewId} postId={reviewId} />) : <tr><td className='No__Mods'>Check back soon for more content</td></tr>}
                  </List>
                  <Box component="span">
                    <Pagination
                      count={noOfPages}
                      page={page}
                      onChange={handleChange}
                      sx={{justifyContent: "center",
                      padding: "10px"}}
                    />
                  </Box>
              </Paper>
            </Grid>

            {/* User Submits */}
            <Grid item xs={12} md={12} lg={12} >
                <AddReview module_name={module_name} moduleId={moduleId}/>
            </Grid>
          </Grid>
        </Container>)
  }
  return content
}

export default Reviews