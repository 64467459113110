import { Outlet, Link, useNavigate } from "react-router-dom";
import { selectUserById } from "../features/user/usersApiSlice";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "../features/auth/auth";
import { useState } from "react";
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import {  useTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Drawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import NotificationsIcon from '@mui/icons-material/Notifications';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Menu from '@mui/material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import AdbIcon from '@mui/icons-material/Adb';
import ModulesList from "../features/module/Modules_sidebar";
import { lightBlue } from "@mui/material/colors";
import Module from "../features/module/module_sidebar";
import { selectAllModules } from "../features/module/modulesApiSlice";

const drawerWidth = 300;

const settings = ['Profile', 'Community'];

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  }),
);

const Nav = styled(AppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

const Layout = () => {

  const navigate = useNavigate()

  const modules = useSelector(selectAllModules)

  const module_name = modules.map(module => module.module_name)

  const theme = useTheme()

  const [anchorElNav, setAnchorElNav] = useState(null)
  const [anchorElUser, setAnchorElUser] = useState(null)

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget)
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget)
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null)
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null)
  };

  const handleDrawerOpen = () => {
    setOpen(true)
  };

   const  handleDrawerClose = () => {
    setOpen(false)
  };

  const user = useSelector(selectCurrentUser)

  const initials = user.firstname.charAt(0).concat(user.lastname.charAt(0))

  const [open, setOpen] = useState(false)

  //clear local storage when login out
  const clearUserCode = () => {
    var key
    for (var i = 0; i < localStorage.length; i++) {
       key = localStorage.key(i)
      if(key != 'persist:root') {
        localStorage.removeItem(key)
      }
    }
  }

    return (
      <>
    <Box sx={{ display: 'flex' }}>
      <Nav position="fixed" data-testid="nav" open={open} sx={{backgroundColor:'#B0D7FF'}}>
        <Toolbar >
          <IconButton
            color="inherit"
            id="opensidebar"
            onClick={handleDrawerOpen}
            edge="start"
            data-testid='sidebar-opener'
            sx={{ mr: 2, ...(open && { display: 'none' }) }}
          >
            <MenuIcon />
          </IconButton>
          <Tooltip title="Home">
          <Typography
            variant="h6"
            noWrap
            component="a"
            data-testid="toolbar"
            href="/layout"
            sx={{
              mr: 2,
              display: { xs: 'none', md: 'flex' },
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            CAPCODES
          </Typography>
          </Tooltip>
          <Typography
            variant="h5"
            noWrap
            component="a"
            href=""
            sx={{
              mr: 2,
              display: { xs: 'flex', md: 'none' },
              flexGrow: 1,
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            CAPCODES
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
           
          </Box>

          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Check it out">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
              <Avatar sx={{ bgcolor: lightBlue[500] }} data-testid="init">{initials}</Avatar>
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
                <MenuItem onClick={() => {
                  handleCloseUserMenu()
                  navigate('/layout/posts')
                }}>Community</MenuItem>
                <MenuItem data-testid="profile" onClick={() => {
                  handleCloseUserMenu()
                  navigate(`/layout/profile/${user._id}`)
                }}>Profile</MenuItem>
                <MenuItem onClick={() => {
                  handleCloseUserMenu()
                  navigate('/')
                  clearUserCode()
                }} >Logout</MenuItem>               
            </Menu>
          </Box>
        </Toolbar>
      </Nav>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider data-testid='divider'/>
        <ModulesList data-testid="lessonlist"/>
      </Drawer>
      <Main open={open}>
        <DrawerHeader />

        <Outlet />
      </Main>
    </Box>
        
      </>
    )
  };
  
  export default Layout;