
import { useSelector } from 'react-redux'
import AddPost from './AddPost.js'
import Post from './Post.js'
import { selectAllPosts, useGetPostsQuery } from './postApiSlice.js'
import { useEffect, useState } from 'react'
import { Box, Container, Divider, Grid, List, Pagination, Paper, Typography, makeStyles } from '@mui/material'

//query and list all post 
const Posts = () => {

  //rtk query posts
  const {
      data: posts,
      isLoading,
      isSuccess,
      isError,
      error
  } = useGetPostsQuery("postList", {
    refetchOnFocus:true,
    refetchOnMountOrArgChange:true
})

//paginataion stuff
const itemsPerPage = 7
const [page, setPage] = useState(1)
const handleChange = (event, value) => {
  setPage(value);
}

  let content

  if (isLoading) content = <p className='module-loading'>Loading...</p>

  if (isError) {
      content = (
        <>
        <h1 className='Module_Title'>Community</h1>
 
         <div>
 
           <table className="table table--notes">
               <tbody>
                   No Post Found
               </tbody>
           </table>
 
           </div>
 
         
         <AddPost/>
         </>)
  }

  if (isSuccess) { 
    
    //get memoized id
    const { ids } = posts
    
    //dynamically render number of pages
    let noOfPages = Math.ceil(ids.length / itemsPerPage)


      content = (
        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <Grid container spacing={4}>

               {/* Previous*/}
               <Grid item xs={12} md={8} lg={12} >
                <Paper
                  sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    height: 100,
                  }}
                >
                  <Typography variant='h2' textAlign={'center'}>Community</Typography>
                </Paper>
              </Grid>

              {/* Donut Chart */}
              <Grid item xs={12} md={12} lg={12}>
                <Paper
                  sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    height: 'fit-content',
                  }}
                >
                  <Divider/>
                    <List dense component="span">
                      {ids.length ? ids
                        .slice((page - 1) * itemsPerPage, page * itemsPerPage)
                        .map(postId => <Post key={postId} postId={postId} />) : <tr><td className='No__Mods'>Check back soon for more content</td></tr>}
                    </List>
                    <Box component="span">
                      <Pagination
                        count={noOfPages}
                        page={page}
                        onChange={handleChange}
                        sx={{justifyContent: "center",
                        padding: "10px"}}
                      />
                    </Box>
                </Paper>
              </Grid>

              {/* User Submits */}
              <Grid item xs={12} md={12} lg={12} >
                  <AddPost/>
              </Grid>
            </Grid>
          </Container>)
  }
  return content
}

export default Posts