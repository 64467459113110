import React, { useEffect } from 'react';
import { javascript } from '@codemirror/lang-javascript';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import CodeMirror from '@uiw/react-codemirror';
import base64 from 'base-64'
import axios from 'axios'
import CodeOutput from './Output';
import setupsourcecode from './formatSourceCode';
import boilercode from './boilercode';
import { selectCurrentUser, updateUserCredentials} from '../auth/auth';
import { selectAllModules, selectModulesById } from './modulesApiSlice';
import Reviews from '../posts/Reviews';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';

import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';

import BookmarkBorderRoundedIcon from '@mui/icons-material/BookmarkBorderRounded';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import RefreshIcon from '@mui/icons-material/Refresh';
import { Box } from '@mui/material';



//styles for buttons
const paperSX = {
  p: 2,
  display: 'flex',
  flexDirection: 'column',
  height: 100,
  "&:hover": {
    backgroundColor: '#B0D7FF'
  },
}

//style for submit/refresh
const submitSX = {
  p: 2,
  display: 'flex',
  flexDirection: 'column',
  height: 75,
  "&:hover": {
    backgroundColor: '#B0D7FF'
  },
}

//storing the written code
var codeToRun = ''


//render component with module info 
const BackendEditor = ({module}) => {

//get module id
const { id } = useParams()

//get module info 
const moduleinfo = useSelector(state => selectModulesById(state, id))

//wrapping boilercode into submission
let startingcode = boilercode(module.boilercode)

//store usercode state
let [usercode, setUserCode] = useState(startingcode)

//load the usercode or use starting code when opening assignment
const loadUserCode = () => {
  if (localStorage.getItem(module.module_name)) {
    setUserCode(localStorage.getItem(module.module_name))
  } else {
    setUserCode(startingcode)
  }
}

//this will load the user code when the starting code changes
  useEffect(() => {
    loadUserCode()
  },[startingcode])

//get user information from state
const user = useSelector(selectCurrentUser)

//dispatch variable for changing state
const dispatch = useDispatch()

//navigate variable for  possible @hint feature
const navigate = useNavigate();

//displaying output to output component
var [output, setOutput] = useState("");

//variable to hold previous rewards + new rewards
let updated_reward



//get all modules from state
const modules = useSelector(selectAllModules)

//get all bookmarks from state
let bookmarks = Array.from(user.Bookmarks)

const getLessonNumber = (module_name) => {
  var num = module_name.replace(/[^0-9]/g, ''); 
  return parseInt(num,36); 
}


//sorting modules for next and previous buttons
const sortedmodules = [...modules].sort((a, b) => getLessonNumber(a.module_name) - getLessonNumber(b.module_name))

//store previous and next lessons
let prev
let next
var currentModule = [...sortedmodules].find(module => module._id == id)
var current = [...sortedmodules].indexOf(currentModule)
prev = [...sortedmodules][current - 1]
next = [...sortedmodules][current + 1]




//local state data being sent to DB
const [submitDBData, setDBSubmitData] = useState({
  userID:user._id,
  source_code: codeToRun,
  language_id: moduleinfo.language_id,
  moduleId: moduleinfo._id,
  module_name: moduleinfo.module_name,
  stdin:'Y2FwY29kZXM=',
  expected_ouput: '0',
})




//local state data being sent to Judge0
const [submitData, setSubmitData] = useState({
  userID:user._id,
  source_code: codeToRun,
  language_id: moduleinfo.language_id,
  stdin:'Y2FwY29kZXM=',
  expected_ouput: '0'
})



 


//custom onChange function to keep track of changes to CodeMirror. Both Judge0 and DB POST request will use this data
const onChange = (value,viewupdate) => {
  codeToRun = value;
  var base64_encoded_value = base64.encode(setupsourcecode(module.boilercode,module.testcases,codeToRun))
  var e = setSubmitData ({...submitData,language_id:module.language_id,source_code: base64_encoded_value})
  localStorage.setItem(module.module_name,codeToRun)
  setUserCode(codeToRun)
  setDBSubmitData ({...submitDBData,language_id:module.language_id,module_name:module.module_name,source_code: codeToRun})
  return codeToRun
}


//function to reward user
const rewarduser = async ()  => {

  //store reward from module
  var lesson_reward = module.Reward


  //store all previous rewards from user. Adds new reward from module if not present
  updated_reward = Array.from(user.Rewards)
  if(!updated_reward.includes(lesson_reward)){
    updated_reward.push(lesson_reward)
  }


  //Create data object for state and update request. 
  var updateduserinfo = {
    _id: user._id,
    username:user.username,
    password:user.password,
    firstname:user.firstname,
    lastname:user.lastname,
    Age:user.Age,
    Streak: user.Streak,
    roles: user.roles,
    active: true,
    Rewards:updated_reward,
    next_lesson: String(module._id),
    Bookmarks:user.Bookmarks
  }


  //send updateduserinfo to DB to have user info updated
var config = {
    method: 'PATCH',
    url: 'https://capcodes-api.onrender.com/users',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    },
    data: updateduserinfo
}

  await axios(config)
    .then(function (response) {
        console.log(JSON.stringify(response.data))

        //updates state to match updated user with new reward
        dispatch(updateUserCredentials(updateduserinfo))
    })
    .catch(function (error) {
        console.log(error)
    })
}

//function to handle bookmarking
const handleBookmarks = async ()  => {

  //store reward from module
  var lessonId = module._id

  //store all previous rewards from user. Adds new reward from module if not present
  if(!bookmarks.includes(lessonId)){
    bookmarks.push(lessonId)
  }
  else {
    bookmarks = bookmarks.filter(bookmark => bookmark !== lessonId)
    console.log(lessonId)
    console.log(bookmarks)
  }

  //Create data object for state and update request. 
  var updateduserinfo = {
    _id: user._id,
    username:user.username,
    password:user.password,
    firstname:user.firstname,
    lastname:user.lastname,
    Age:user.Age,
    Streak: user.Streak,
    roles: user.roles,
    active: true,
    Rewards:user.Rewards,
    next_lesson: String(module._id),
    Bookmarks:bookmarks
  }

  //send updateduserinfo to DB to have user info updated
var config = {
    method: 'PATCH',
    url: 'https://capcodes-api.onrender.com/users',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    },
    data: updateduserinfo
}

  await axios(config)
    .then(function (response) {
        console.log(JSON.stringify(response.data))

        //updates state to match updated user with new reward
        dispatch(updateUserCredentials(updateduserinfo))
    })
    .catch(function (error) {
        console.log(error)
    })
}

//function to load next lesson from db
const loadNextLesson = async ()  => {

  //store reward from module
  var nextLesson = String(module._id)

  //Create data object for state and update request. 
  var updateduserinfo2 = {
    _id: user._id,
    username:user.username,
    password:user.password,
    firstname:user.firstname,
    lastname:user.lastname,
    Age:user.Age,
    Streak: user.Streak,
    roles: user.roles,
    active: true,
    Rewards:user.Rewards,
    next_lesson: nextLesson,
    Bookmarks:user.Bookmarks
  }

  //send updateduserinfo to DB to have user info updated
var config = {
    method: 'PATCH',
    url: 'https://capcodes-api.onrender.com/users',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    },
    data: updateduserinfo2
}

  await axios(config)
    .then(function (response) {
        console.log(JSON.stringify(response.data))

        //updates state to match updated user with new reward
        dispatch(updateUserCredentials(updateduserinfo2))
    })
    .catch(function (error) {
        console.log(error)
    })
}

//post submission data to DB
const posttoDB = async () => {
  var config = {
    method: 'POST',
    url: 'https://capcodes-api.onrender.com/submitPOST',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    },
    data: submitDBData
}
              
  await axios.request(config)
      .then(function (response) {
          console.log(JSON.stringify(response.data))
      })
      .catch(function (error) {
          console.log(error)
      })
}

//function to check for repsponse token from Judge0 every two seconds
const checkStatus = async (token) => {
  console.log("checking status")
  output = setOutput('Code Submitted waiting for response')
  const options = {
    method: "GET",
    url: 'https://judge0-ce.p.rapidapi.com/submissions/' + token,
    params: { base64_encoded: "true", fields: "*" },
    headers: {
      'X-RapidAPI-Key': '0f23b2d723msh6044964d393ec04p164f0djsn82eddec74165',
      'X-RapidAPI-Host': 'judge0-ce.p.rapidapi.com',
    }
  }
  try {
    //response will have a statusID to indicate if processing is complete
    let response = await axios.request(options);
    let statusId = response.data.status?.id;

    if (statusId === 1 || statusId === 2) {
      //if statusID = 1 or 2, it means that submission is still in processing
      //  processing --> so run again the same token after 2s
      setTimeout(() => {
        checkStatus(token)
      }, 2000)
      return
    } else {
      // processing complete --> check full response data. If compile error display compile error else display output to output and rewarduser
      if (response.data.stdout === null) {
        output = setOutput(base64.decode(response.data.compile_output))
        console.log(output)
        output = setOutput('Wrong! Try again!')
      } else {
        console.log('response.data', response.data)
        console.log(base64.decode(response.data.stdout))
        console.log(base64.decode(response.data.compile_output))
        output = setOutput(base64.decode(response.data.stdout))
        rewarduser()
      }
    }
  } catch (err) {
    console.log("err", err);
     output = setOutput('Server Error! Please try again!')
  }
}


//function to send submission data to Judge0, wait for response data, then call check token function when clicking on submit
    const handleSubmit = async (e) => {
      e.preventDefault();
       console.log(submitData)

       output = setOutput('Code Submitted...Compiling')
        
        const options = {
         method: 'POST',
        url: 'https://judge0-ce.p.rapidapi.com/submissions',
        params: {base64_encoded: 'true', fields: '*'},
        headers: {
        'Content-Type': 'application/json',
        'X-RapidAPI-Key': '0f23b2d723msh6044964d393ec04p164f0djsn82eddec74165',
        'X-RapidAPI-Host': 'judge0-ce.p.rapidapi.com',
        },
        data: submitData
      }

      await axios
      .request(options)
      .then(function (response) {
        console.log("res.data", response.data)
        posttoDB()
        loadNextLesson()
        const token =  response.data.token
        checkStatus(token)
      })
      .catch((err) => {
        let error = err.response ? err.response.data : err
        output = setOutput('Server Error! Please try again!')
        console.log(error)
      })

    }

let prevTitle = prev ? prev.module_name : "Back To Menu"

let nextTitle = next ? next.module_name : "End"

const isBookmarked = Boolean(bookmarks.includes(module._id))

  //on click handling prev
  const handlePrev = async (e) => {
    if (prev) {
      navigate(`/layout/modules/${prev._id}`)
      setOutput('')
    } else {
      navigate(`/layout`)
    }
  }

  //on click handling next
  const handleNext = async (e) => {
    if (next) {
      navigate(`/layout/modules/${next._id}`)
      setOutput('')
    } else {
      navigate(`/layout`)

    }
  }

  //on click handling refresh
  const refresh = () => {
    setUserCode(startingcode)
  }


    //Component setup
    return (
      <>
          <Toolbar />
          <Container maxWidth="lg" sx={{ mb: 4 }}>
            <Grid container spacing={4}>

              {/* Lesson Name*/}
              <Grid item xs={12} md={12} lg={12} >
                <Paper
                  sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    height: 125,
                  }}
                >
                <Typography variant='h3' textAlign={'center'}>{module.module_name}</Typography>
                </Paper>
              </Grid>

              {/* Lesson Stuff*/}
              <Grid item xs={12} md={12} lg={12} zeroMinWidth>
                <Paper
                  sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    height: 300,
                  }}
                >
                <Typography variant='h5' sx={{mb: 1}}>{module.Blob_Desc}</Typography>
                <Typography variant='h5'sx={{mb: 1}}>{module.Full_Desc}</Typography>
                  <CodeMirror
                    height="75px"
                    width='725px'
                    border="1px #FFFFF"
                    overflow='auto'
                    editable= {'false'}
                    extensions={[javascript({ jsx: true })]}
                    theme = {'dark'}
                    value = {module.Example}
                  />
                </Paper>
              </Grid>

              {/* CodeMirror */}
              <Grid item xs={12} md={12} lg={8}>
                  <CodeMirror
                      className='editor'
                      height="575px"
                      width='725px'
                      id='editor'
                      extensions={[javascript({ jsx: true })]}
                      theme = {'dark'}
                      onChange = {onChange}
                      value = {usercode}
                  />
              </Grid>

              {/* Output */}
              <Grid item xs={12} md={8} lg={4} >
                <Paper
                  sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    height: 600,
                    backgroundColor:'#B0D7FF'
                  }}
                >
                  <CodeOutput className='output' output={output}/>
                </Paper>
              </Grid>

              {/* Submit*/}
              <Grid item xs={12} md={7} lg={10} >
                <Paper
                onClick={handleSubmit}
                  sx={submitSX}
                >
                <Typography variant='h4' textAlign={'center'} mb={2} >Submit</Typography>
                </Paper>

              </Grid>

              {/* refresh */}
              <Grid item xs={12} md={1} lg={2} >
                <Paper
                onClick={refresh}
                  sx={submitSX}
                >
                <Box m={'auto'}><RefreshIcon  sx={{ fontSize: 40}}/></Box>
                </Paper>

              </Grid>

              {/* prev */}
              <Grid item xs={12} md={8} lg={4} >
                <Paper
                  onClick={handlePrev}
                  sx={paperSX}
                >
                  <Typography variant='h5' textAlign={'center'} mb={2}>{prevTitle}</Typography>
                </Paper>
              </Grid>

              {/* bookmark */}
              <Grid item xs={12} md={8} lg={4} >
                <Paper
                  onClick={handleBookmarks}
                  sx={paperSX}
                >
                  <Typography variant='h5' textAlign={'center'} mb={2}> {isBookmarked ? (<Container><Typography variant='h5'>Bookmark</Typography><BookmarkIcon/></Container>) : (
               <Container><Typography variant='h5'>Bookmark</Typography><BookmarkBorderRoundedIcon/></Container>
              )}</Typography>
                </Paper>
              </Grid>

              {/* next */}
              <Grid item xs={12} md={8} lg={4} >
                <Paper
                onClick={handleNext}
                  sx={paperSX}
                > 
                <Typography variant='h5' textAlign={'center'} mb={2} >{nextTitle}</Typography>
                </Paper>
              </Grid>

              {/* Comments */}
              <Grid item xs={12}>
                <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', backgroundColor:'#B0D7FF' }}>
                <Reviews moduleId={id} module_name={moduleinfo.module_name}/> 
                </Paper>
              </Grid>
            </Grid>
          </Container>
    </>
      );
};


 export default BackendEditor;