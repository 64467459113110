import { useSendLogoutMutation } from '../features/auth/authapi'
import { useNavigate, Link, useLocation } from 'react-router-dom'
import { useEffect,useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectCurrentUser } from '../features/auth/auth'
import { GetStateFromSelectors } from 'reselect';
import EditUser from '../features/user/edituser';
import EditUserForm from '../features/user/EditUserForm';
import { selectModulesById } from '../features/module/modulesApiSlice';
import Module from '../features/module/module_sidebar';

//material ui stuff
import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import CameraIcon from '@mui/icons-material/PhotoCamera';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { List, ListItem, Paper } from '@mui/material';
import Avatar from '@mui/material/Avatar';




const Profile = () => {


  const user = useSelector(selectCurrentUser)
 
  const initials = user.firstname.charAt(0).concat(user.lastname.charAt(0))

  const navigate = useNavigate()

  const [sendLogout, {
      isSuccess
  }] = useSendLogoutMutation()

  const logoutButton = (
    <button
        className="hero-button"
        title="Logout"
        onClick={sendLogout}
    >Logout
    </button>
  )

  useEffect(() => {
      if (isSuccess) navigate('/')
  }, [isSuccess, navigate])


  const toedit = () => {
    navigate('/layout/profile/edit')
  }

  const rewards = Array.from(user.Rewards)

  const bookmark_ids = Array.from(user.Bookmarks)

  const bookmarks = bookmark_ids?.length && bookmark_ids.map(moduleId => <Module key={moduleId} moduleId={moduleId} />)

  console.log(user)



    return( 
    <>
      <Container maxWidth="lg">
      <Grid container spacing={5}>
        <Grid item xs={12} md={12} lg={4}>
      <Avatar sx={{ width: 300, height: 300, mt:7}} data-testid="avatar">{initials}</Avatar>
      </Grid>
      <Grid item xs={12} md={12} lg={8}>
      <Paper
                  sx={{
                    p: 5,
                    display: 'flex',
                    flexDirection: 'column',
                    height: 500,
                  }}
                >
        <Typography
          component="h1"
          variant="h2"
          align="center"
          color="text.primary"
          gutterBottom
        >
          {`${user.firstname} ${user.lastname}`}
        </Typography>
        <Typography variant="h5" align="left" color="text.secondary" paragraph data-testid="username">
                Username: {`${user.username} `}
        
                <Typography>Age: {`${user.Age} `}</Typography>

        </Typography>
        <Stack
          sx={{ pt: 4 }}
          direction="row"
          spacing={2}
          justifyContent="center"
        >
          <Button variant="contained" onClick={() => navigate(`edit`)}>Edit User</Button>
          <Button variant="outlined" onClick={sendLogout}>Logout</Button>
        </Stack>
        </Paper>
        </Grid>
        </Grid>
      </Container>
    <Container sx={{ py: 8 }} maxWidth="lg">
      {/* End  unit */}
      <Grid container spacing={4}>
       {/* Total Chart*/}
       <Grid item xs={12} md={8} lg={6} >
                <Paper
                  sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    height: 400,
                    overflow:'auto'
                  }}
                >
                  <Typography
          component="h4"
          variant="h2"
          align="center"
          color="text.primary"
          gutterBottom
        >
          Bookmarks
        </Typography>
                  <Typography>{bookmarks}</Typography>
                </Paper>
              </Grid>

              {/* User Progress*/}
              <Grid item xs={12} md={8} lg={6} >
                <Paper
                  sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    height: 400,
                    overflow:'auto'
                  }}
                > 
                <Typography
          component="h4"
          variant="h2"
          align="center"
          color="text.primary"
          gutterBottom
        >
          Rewards
        </Typography>
                <List data-testid='rewards'>
                {rewards.map(reward => <ListItem key={reward}>{reward}</ListItem>)}
                </List>
                
                </Paper>
              </Grid>
          </Grid>
    </Container>
  </>
  )
  }
  
  export default Profile;