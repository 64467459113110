import {Link, Outlet} from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useRef, useState, useEffect } from 'react'
import axios from "axios";
//added
import Button from '@mui/material/Button';
import  Paper  from "@mui/material/Paper";
import  Avatar from "@mui/material/Avatar";
import TextField from '@mui/material/TextField';
import  Typography  from "@mui/material/Typography";
import  Grid  from "@mui/material/Grid";



const Signup = () => {

    const navigate = useNavigate()


    const [userData, setUserData] = useState ({
        username:'',
        password:'',
        firstname:'',
        lastname:'',
        Age:'',
        Streak: 0,
        roles: ["user"],
        active: true,
        Rewards:[],
        next_lesson:'6383d06cf8387112e82da5e8',
        Bookmarks:[]
    })


    const onUsernameChanged = e => setUserData({...userData, username: e.target.value})
    const onPasswordChanged = e => setUserData({...userData, password: e.target.value})
    const onFirstnameChanged = e => setUserData({...userData, firstname: e.target.value})
    const onLastNameChanged = e => setUserData({...userData, lastname: e.target.value})
    const onAgeChanged = e => setUserData({...userData, Age: e.target.value})

    const handleSubmit = async (e) => {
        e.preventDefault()
       
        var config = {
            method: 'POST',
            url: 'https://capcodes-api.onrender.com/users',
            headers: {
              'Content-Type': 'application/json',
              'Access-Control-Allow-Origin': '*',
            },
            data: userData
        };
                    
        axios(config)
            .then(function (response) {
                console.log(JSON.stringify(response.data));
                navigate('/')
            })
            .catch(function (error) {
                console.log(error);
            });
        
    }

    //added
    const paperStyle={padding :30,height:500,width:300, margin:'5rem auto',}
    const avatarStyle={backgroundColor:'#1c6cbc'}
    const btnstyle={margin:'17px 0'}
   
   return (

    <Grid>
        <Paper elevation={10} style = {paperStyle}> 
            <Grid align = 'center'>
            <Avatar style={avatarStyle}> CC </Avatar>
            <Typography paddingBottom={1}> Create Account </Typography>
            </Grid>
                <TextField id="outlined-basic"  placeholder='username[0-15]' value={userData.username} onChange={(e) => setUserData({...userData, username: e.target.value})} fullWidth inputProps={{ maxLength: 15 }} required sx={{paddingTop:1}}/>
                <TextField id="outlined-basic"  placeholder='password[0-15]' value={userData.password} onChange={(e) => setUserData({...userData, password: e.target.value})} fullWidth inputProps={{ maxLength: 15 }} required sx={{paddingTop:1}}/> 
                <TextField id="outlined-basic"  placeholder='first name' value={userData.firstname} onChange={(e) => setUserData({...userData, firstname: e.target.value})} fullWidth inputProps={{ maxLength: 50 }} required sx={{paddingTop:1}}/>
                <TextField id="outlined-basic"  placeholder='last name' value={userData.lastname} onChange={(e) => setUserData({...userData, lastname: e.target.value})} fullWidth inputProps={{ maxLength: 50 }} required sx={{paddingTop:1}}/> 
                <TextField id="outlined-basic"  placeholder='age' type="number" value={userData.Age} onChange={(e) => setUserData({...userData, Age: e.target.value})} fullWidth inputProps={{ maxLength: 12 }} required sx={{paddingTop:1}}/>
               <Button variant="contained" style={btnstyle} onClick={handleSubmit} fullWidth> Sign Up </Button>
               {/* <Button type='submit' color='blue' variant="contained" style={btnstyle} fullWidth> Sign Up </Button> */}
               
            </Paper>

        </Grid>
   )

/* <>
    <div className= "text-box">
    <h1>Signup</h1>
        <p> Get started coding today</p>
        <form className="signup-form" onSubmit={handleSubmit}>
                    <label className="username">Username:</label>
                    <input
                        className="form__input"
                        type="text"
                        id="username"
                        onChange={onUsernameChanged}
                        autoComplete="off"
                        required
                    />

                    <label className="password">Password:</label>
                    <input
                        className="form__input"
                        type="password"
                        id="password"
                        onChange={onPasswordChanged}
                        required
                    />
                    <label className="firstname">Firstname:</label>
                    <input
                        className="form__input"
                        type="text"
                        id="username"
                        onChange={onFirstnameChanged}
                        autoComplete="off"
                        required
                    />
                    <label className="lastname">Lastname:</label>
                    <input
                        className="form__input"
                        type="text"
                        id="lastname"
                        onChange={onLastNameChanged}
                        autoComplete="off"
                        required
                    />
                    <label className="age">Age:</label>
                    <input
                        className="form__input"
                        type="text"
                        id="age"
                        onChange={onAgeChanged}
                        autoComplete="off"
                        required
                    />
                    <button className="form__submit-button">Create Account</button>
                </form>
</div>

<Outlet/>

</> */

   
  };
  
  export default Signup;