import { Link, useNavigate } from "react-router-dom";
import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Login from "./Login";
import Img1 from '../assets/Coverpage.jpg'
import Img2 from '../assets/Cover.png'
import Img3 from '../assets/Img3.PNG'
import Img4 from '../assets/Img4.JPEG'
import Img5 from '../assets/Img5.JPEG'
import Img6 from '../assets/Img6.JPEG'
import Img7 from '../assets/Img7.png'
import { Card, CardActionArea, CardMedia, ImageList, ImageListItem } from "@mui/material";

//Incorpoate Login and Demo Video
const Splash = () => {
    const navigate = useNavigate();
    const theme = createTheme();
    const itemData = [
        {
          img: Img2,
          title: 'Dashboard',
        },
        {
          img: Img3,
          title: 'Lesson',
        },
        {
            img: Img4,
            title: 'Dashboard with Module sidebar',
        }

      ]
   return (
    <ThemeProvider theme={theme}>
      <Grid container component="main" sx={{ height: '100vh' }}>
        <CssBaseline />
        <Grid
         item
         xs={false}
         sm={4}
         md={6}
         sx={{
           backgroundImage: `url(${Img1})`,
           backgroundRepeat: 'no-repeat',
           backgroundSize: 'cover',
           backgroundPosition: 'center',
         }}
        >
        </Grid>
        <Grid item xs={12} sm={8} md={6} component={Paper} elevation={6} backgroundColor='#282c34' square>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Typography variant="h2" color={"white"}>Capcodes</Typography>
            <Login/>
            <Typography variant="h4" align="center" color={"white"} >Learn what it takes to code Today!</Typography>
            <Typography variant="h6" align="center" color={"white"}>This app is designed to teach you all you need to prepare for that next interview, assignment, or just to have fun</Typography>
            <Typography variant="h4" align="center" color={"white"} mt={2}>Get up close and personal with your favorite language</Typography>
            <Typography variant="h6" align="center" color={"white"}>The lessons are designed to give you optimal understanding of language syntax and provide an environment to go above and beyond any other online text editor</Typography>
            <Typography variant="h4" align="center" color={"white"} mt={2}>Track your Progress</Typography>
            <Typography variant="h6" align="center" color={"white"}>Your progress is tracked for you and see how you stack against the rest</Typography>
            <Typography variant="h4" align="center" color={"white"} mt={2}>Join the community</Typography>
            <Typography variant="h6" align="center" color={"white"}>Join the group to provide or see solutions and have fun with the rest</Typography>

    <Box mt={5}>
        <ImageList sx={{ minWidth: 400, height: 1200, maxWidth: 600}} cols={1} rowHeight={300} >
      {itemData.map((item) => (
        <ImageListItem key={item.img}>
          <img
            src={`${item.img}?w=164&h=164&fit=crop&auto=format`}
            srcSet={`${item.img}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
            alt={item.title}
            loading="lazy"
          />
        </ImageListItem>
      ))}
        </ImageList>
        </Box>

        <Box mt={5}>
          <iframe width="700"
               height="400" 
               src="https://www.youtube.com/embed/fGItZkBV0vw" 
               title="TheRollingCaptsones - Final Demo Video" 
               frameborder="0" 
               allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
               allowfullscreen="allowfullscreen">
            </iframe>
        </Box>

        <Typography variant="h6" align="center" color={"white"} mt={2}>Feel free to contact us</Typography>
        <Typography variant="h6" align="center" color={"white"} ><a href={'https://www.linkedin.com/in/brandon-jennings-870496184/'} target="blank" style={{ color: '#FFF' }}>Brandon Jennings</a> - btj2@email.sc.edu</Typography>
        <Typography variant="h6" align="center" color={"white"} ><a href={'https://www.linkedin.com/in/keerthana-guzzarlamudi/'} target="blank" style={{ color: '#FFF' }}>Keerthana Guzzarlamudi</a> - guzzarlk@email.sc.edu</Typography>
        <Typography variant="h6" align="center" color={"white"} ><a href={ 'https://www.linkedin.com/in/nijel-felder-7539a6162/'} target="blank" style={{ color: '#FFF' }}>Nijel Felder</a> - ntfelder@email.sc.edu</Typography>
        <Typography variant="h6" align="center" color={"white"} ><a href={'https://www.linkedin.com/in/mercybarigala/'} target="blank" style={{ color: '#FFF' }}>Mercy Barigala</a> - mercyb@email.sc.edu</Typography>
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
  };
  
  export default Splash;