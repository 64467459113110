
import { selectAllModules } from '../module/modulesApiSlice';
import { useSelector } from 'react-redux'
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import "react-circular-progressbar/dist/styles.css";

import Typography from '@mui/material/Typography';
import { Box } from '@mui/material';
import { selectCurrentUser } from '../auth/auth.js';
import Container from '@mui/material/Container';

//Code to render progress bar chart
const ProgressBar = () => {
  

const allmodules = useSelector(selectAllModules)

const user = useSelector(selectCurrentUser)

const moduleTotal = Array.from(allmodules)



const usermoduleId = Array.from(user.Rewards)

const percentage = (100 * usermoduleId.length) / moduleTotal.length

console.log(percentage)

const formatted_percentage = percentage.toFixed(2)


if (percentage) {
return (
        <>
        <Box mt={5}>
        <Typography variant='h6' mb={2} sx={{textAlign:'center'}}>Progress</Typography>
        <Container style={{ width: 200, height: 200}}>
        <CircularProgressbar
  value={formatted_percentage}
  text={`${percentage}%`}
  styles={buildStyles({

    // Colors
    pathColor: `#00FF00`,
    textColor: '#282c34',
    trailColor: '#808080',
    backgroundColor: '#3e98c7',
  })}
/>
        </Container>
        </Box>
        </>

        

        
      )
} else return (
  <>
  <Box mt={5}>
  <Typography variant='h6' mb={2} sx={{textAlign:'center'}}>Progress</Typography>
  <Container style={{ width: 200, height: 200}}>
  <CircularProgressbar
value={0}
text={`0%`}
styles={buildStyles({

// Colors
pathColor: `#00FF00`,
textColor: '#282c34',
trailColor: '#808080',
backgroundColor: '#3e98c7',
})}
/>
  </Container>
  </Box>
  </>

  

  
)
  }

export default ProgressBar