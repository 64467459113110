import {
    createSelector,
    createEntityAdapter
} from "@reduxjs/toolkit";
import { apiSlice } from "../../app/api/apiSlice"




export const submitsApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getAllSubmits: builder.query({
            query: () => '/submitPOST',
            providesTags: (result, error, arg) => {
                if (result?.ids) {
                    return [
                        { type: 'Submit_POST', id: 'LIST' },
                        ...result.ids.map(id => ({ type: 'Submit_POST', id }))
                    ]
                } else return [{ type: 'Submit_POST', id: 'LIST' }]
            }
        }),
    }),
})



export const {useGetAllSubmitsQuery} = submitsApiSlice