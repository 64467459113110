

import { useSelector } from 'react-redux'
import { selectModulesById } from './modulesApiSlice'
import { selectCurrentUser } from '../auth/auth';
import { selectAllModules } from './modulesApiSlice';
import { useNavigate } from 'react-router-dom';

import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/material';
import Button from '@mui/material/Button';

const Previous_Module = ({ moduleId }) => {

    const user = useSelector(selectCurrentUser)

    console.log(user)

    const nextLesson = () => {
        if (user.next_lesson === undefined) {
            return '6383d06cf8387112e82da5e8'
        }
        return user.next_lesson
    }

    const next_lesson = useSelector(state => selectModulesById(state, nextLesson()))
    console.log(next_lesson)

    const navigate = useNavigate()



    if (next_lesson) {

        const handlestart = () => navigate(`/layout/modules/${next_lesson._id}`)

        return (
            <>
        <Box onClick={handlestart}>
            <Typography variant='h2' mt={2.5}>This Is where you left off!</Typography>
                <Typography  variant="h4" mt={2}>
            {next_lesson.module_name}
                </Typography>
      <Typography variant='h5' mt={2}>
        {next_lesson.Blob_Desc}
      </Typography>
      <Box mt={4}>
      <Button variant="contained" sx={{backgroundColor:'#B0D7FF'}} onClick={handlestart} >Go To Lesson</Button>
      </Box>
      </Box>
    </>

            
        )

    } else return null
}
export default Previous_Module